module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/icon.png"},
    },{
      plugin: require('../node_modules/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"andreablahovaportfolio","accessToken":"MC5Yak1ieVJBQUFDSUFlWXZa.Du-_vR9o77-9e--_ve-_vXV4Fu-_ve-_vV5FO--_vRVN77-977-977-977-977-977-9Pe-_ve-_vWsr77-977-9","pages":[{"type":"Project","match":"/projects/:uid","path":"/projects/drafts","component":"/opt/build/repo/src/templates/Project.js"},{"type":"Event","match":"/events/:uid","path":"/events/drafts","component":"/opt/build/repo/src/templates/Event.js"}],"sharpKeys":[{},"landing_photo_desktop","landing_photo_mobile","photo_desktop","photo_mobile"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
